<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Checkbox v-model="autoReload" border>自动刷新</Checkbox>
      </FormItem>
      <FormItem>
        <Select v-model="unit" placeholder="图表颗粒度">
          <Option v-for="(unit, key) in STAT_TIME_UNIT" :value="key" :key="key">{{ unit.label }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="cutOffAtRange"
          :type="STAT_TIME_UNIT[unit].controlType"
          placement="bottom-end"
          placeholder="时间范围"
          style="width: 350px"
          :options="datePickerOptions"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <Select v-model="selectedSeries" placeholder="数据集">
          <Option v-for="(item, key) in seriesOptions" :key="key" :value="key">
            {{ item.title }}
          </Option>
        </Select>
      </FormItem>
    </Form>
    <v-chart class="chart" :initOptions="chartInitOptions" :option="chartOption" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { querySummaryStat } from "@/api"
import { STAT_TIME_UNIT } from "@/constants"

import "echarts"

// import { use } from "echarts/core"
// import { CanvasRenderer, SVGRenderer } from "echarts/renderers"
// import { BarChart, LineChart } from "echarts/charts"
import VChart from "vue-echarts"

// use([CanvasRenderer, SVGRenderer, BarChart, LineChart])
const now = new Date()
const midNightNextDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 24, 0, 0, 0)
const midNightNextDayTime = midNightNextDay.getTime()

const seriesOptions = {
  userTotal: { title: "用户总量", seiresDimensions: [{ name: "用户总量", key: "userTotal" }] },
  itemTotal: {
    title: "商品总量",
    seiresDimensions: [
      { name: "当下商品总数", key: "itemTotal" },
      { name: "当下全新商品总数", key: "itemNewTotal" },
      { name: "当下二手商品总数", key: "itemUsedTotal" },
      { name: "当下全新商品总数", key: "itemNormalTotal" },
      { name: "当下二手商品总数", key: "itemFreeTotal" },
      { name: "当下二手商品总数", key: "itemVirtualTotal" },
    ],
  },
  newPublished: {
    title: "新发布增量",
    seiresDimensions: [
      { name: "商品发布数量增量", key: "itemPublishedDelta" },
      { name: "全新商品发布数量增量", key: "itemNewPublishedDelta" },
      { name: "二手商品发布数量增量", key: "itemUsedPublishedDelta" },
      { name: "普通商品发布数量增量", key: "itemNormalPublishedDelta" },
      { name: "公益商品发布数量增量", key: "itemFreePublishedDelta" },
      { name: "虚拟商品发布数量增量", key: "itemVirtualPublishedDelta" },
    ],
  },
  itemOperation: {
    title: "商品交互增量",
    seiresDimensions: [
      { name: "商品被添加到购物车数量增量", key: "itemAddToCartDelta" },
      { name: "全新商品被添加到购物车数量增量", key: "itemNewAddToCartDelta" },
      { name: "二手商品被添加到购物车数量增量", key: "itemUsedAddToCartDelta" },
      { name: "商品被下单数量增量（未必付款）", key: "itemOrderedDelta" },
      { name: "全新商品被下单数量增量（未必付款）", key: "itemNewOrderedDelta" },
      { name: "二手商品被下单数量增量（未必付款）", key: "itemUsedOrderedDelta" },
      { name: "商品真实售出数量增量（以成交为准）", key: "itemSoldDelta" },
      { name: "全新商品真实售出数量增量（以成交为准）", key: "itemNewSoldDelta" },
      { name: "二手商品真实售出数量增量（以成交为准）", key: "itemUsedSoldDelta" },
    ],
  },
  order: {
    title: "订单相关增量",
    seiresDimensions: [
      { name: "订单创建数增量", key: "orderCreatedDelta" },
      { name: "订单付款数量增量", key: "orderPaidDelta" },
      { name: "订单数量增量（无需付款订单）", key: "orderFreePaidDelta" },
      { name: "订单发货数量增量", key: "orderDeliveredDelta" },
      { name: "订单取消数量增量", key: "orderCanceledDelta" },
      { name: "订单取消数量增量", key: "orderConfirmedDelta" },
    ],
  },
  api: {
    title: "接口调用增量",
    seiresDimensions: [
      { name: "API被调用增量", key: "apiCalledCountDelta" },
      { name: "商品被查看总数增量", key: "itemViewedDelta" },
      { name: "商品被分享总数增量", key: "itemSharedDelta" },
      { name: "首页被查看次数增量", key: "appHomeViewedDelta" },
      { name: "升级接口被调用次数增量", key: "updatedDelta" },
      { name: "搜索调用次数增量", key: "searchDelta" },
    ],
  },
}

export default {
  name: "AnalysisSummaryPage",
  components: {
    VChart,
    ContentWrapper,
  },
  data() {
    return {
      topLinks: [{ title: "概要", link: this.$route.path }],
      chartTitle: "标题",
      statDimensions: [],
      statData: [],
      seriesOptions,
      usedSeries: [],
      chartInitOptions: {
        renderer: "svg",
      },
      unit: "HOURLY",
      unitInterval: {
        // FIVE_MINUTELY: {
        //   minInterval: 2 * 60 * 60 * 1000,
        //   maxInterval: 6 * 60 * 60 * 1000,
        // },
        // HOURLY: {
        //   minInterval: 2 * 60 * 60 * 1000,
        //   maxInterval: 6 * 60 * 60 * 1000,
        // },
        // DAILY: {
        //   minInterval: 2 * 60 * 60 * 1000,
        //   maxInterval: 6 * 60 * 60 * 1000,
        // },
        // MONTHLY: {
        //   minInterval: 30 * 60 * 60 * 1000,
        //   maxInterval: 6 * 60 * 60 * 1000,
        // },
      },
      autoReload: true,
      cutOffAtRange: ["", ""],
      datePickerOptions: {
        disabledDate(date) {
          return date && date.valueOf() > midNightNextDayTime
        },
      },
      STAT_TIME_UNIT,
      selectedSeries: "newPublished",
    }
  },
  computed: {
    chartOption() {
      return {
        dataset: {
          dimensions: this.statDimensions,
          source: this.statData,
        },
        tooltip: {
          trigger: "axis"
        },
        title: {
          text: this.chartTitle,
          left: "center",
        },
        xAxis: {
          type: "time",
        },
        yAxis: {
          type: "value",
          minInterval: 1,
        },
        series: this.usedSeries,
        legend: {
          type: "scroll",
          top: "bottom"
        },
      }
    },
  },
  watch: {
    unit() {
      this.reload()
    },
    cutOffAtRange() {
      this.reload()
    },
    selectedSeries(val) {
      this.changeSeries(val)
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        let startTime = null,
          endTime = null
        if (this.cutOffAtRange && this.cutOffAtRange.length == 2 && this.cutOffAtRange[0] instanceof Date) {
          startTime = this.cutOffAtRange[0].getTime()
          endTime = this.cutOffAtRange[1].getTime()
        }
        const data = await querySummaryStat(this.unit, startTime, endTime)
        if (data.dimensions instanceof Array && data.dimensions.length > 0) {
          this.statDimensions = data.dimensions
          this.statData = data.values
          this.changeSeries(this.selectedSeries)
        } else {
          this.statData = []
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    changeSeries(val) {
      const d = seriesOptions[val]
      this.chartTitle = `${d.title}`
      const {seiresDimensions} = d
      this.usedSeries = seiresDimensions.map(item => {
        return {name: item.name, type: item.type ? item.type : "line", encode: {x: "cutOffAt", y: item.key}}
      })
    }
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
